import React from 'react';
import IFrame from '../../Helpers/IFrame';

class CombustiblePage extends React.Component {
    createUrl() {
        const idUnidad = this.props.idUnidadSeleccionada;
        const finalUrl = process.env.REACT_APP_SERVICES_RENDIMIENTOCOMBUSTIBLE
            + "IdUnidad=" + idUnidad
        return finalUrl;
    }

    render() {
        var finalUrl = this.createUrl();

        var finalRender = (
            <div id="CombustiblePage">
                <IFrame src={finalUrl} />
            </div>
        );

        return finalRender;
    }
}

export default CombustiblePage;