import React from 'react';
// import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
// import Button from 'react-bootstrap/Button';
import IFrame from '../IFrame';
import { Dialog } from 'primereact/dialog';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import './MyVerticallyCenteredModal.css';

const MyVerticallyCenteredModal = (props) => {
    let finalRender = (
        <Dialog maximizable header={props.title} visible={props.visible} onHide={props.onHide}>
            <IFrame src={props.url} />
        </Dialog>
    );
    return finalRender;
}

export default MyVerticallyCenteredModal;