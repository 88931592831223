import React from 'react';
import SvgComponent from '../SvgComponent';

const IconComponent = (props) => {
    var finalClass = "Icono btn btn-sm " + props.className;
    var finalRender = (

        <i className={finalClass}>
            <SvgComponent icono={props.icono} />
        </i>
    );
    return finalRender
};

export default IconComponent;