import React from 'react';
import SearchBar from './SearchBar';
import './TodasMisUnidadesPage.css'
import ConnectComponent from '../../Helpers/Connect';
import FetchData from '../../Helpers/FetchData';
import Moment from 'react-moment';
import moment from 'moment';

class TodasMisUnidadesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      unidades: [],
      fechaUltimaActualizacion: new Date()
    }

    this.interval = null;
    this.tiempoActualizacion = 60 * 1000;
  }

  getData() {
    var finalUrl = process.env.REACT_APP_SERVICES_TODAS_UNIDADES + "IdCuenta=" + this.props.usuarioActual.IdCuenta;

    fetch(finalUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("not ok");
        }
        return response.json();
      })
      .then((result) => {
        this.setState({ unidades: result, fechaUltimaActualizacion: new Date() });
      })
      .catch((err) => {
        console.info("error fetch. " + err);
      });
  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => this.getData(), this.tiempoActualizacion);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var finalRender = null;
    if (this.state.unidades.length > 0) {
      finalRender = (
        <div id="TodasMisUnidadesPage">
          {this.renderUnidades(this.state.unidades)}
          {this.renderTiempoRefresco()}
        </div >
      );
    }

    return finalRender;
  }

  renderUnidades(unidades) {
    this.props.PintarUnidades(unidades);

    var finalRender = (
      <SearchBar
        {...this.props}
        unidades={unidades} >
      </SearchBar>
    );
    return finalRender;
  }

  renderTiempoRefresco() {
    const formatCountDown = (d) => {
      var tiempo = Math.abs(d);

      var segundoStr = (tiempo <= 1) ? "segundo" : "segundos";
      return "El mapa se refrescará en " + tiempo + " " + segundoStr;
    };

    // TIEMPO DE ACTUALIZACIÓN
    var tiempoEn1Minuto = new Date(this.state.fechaUltimaActualizacion.getTime() + (this.tiempoActualizacion));

    var render = (
      <div className="TiempoRefresco">
        <Moment diff={tiempoEn1Minuto} unit="seconds" interval={1000} filter={formatCountDown}>
        </Moment>
      </div>
    );
    return render;
  }


}

export default TodasMisUnidadesPage;