import React from 'react';
import { Button } from 'semantic-ui-react';

class DownloadButton extends React.Component {
    render() {
        return (
            <a className="btn btn-info" href={this.props.url}>Exportar Excel</a>
        )
    }
}

export default DownloadButton;