import React from 'react';

const SvgComponent = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1em" height="1em"
		>
			<use xlinkHref={`${props.icono}#def`} />
		</svg>
	);
};

export default SvgComponent;