import React from 'react';
import FetchData from '../../Helpers/FetchData';
import Calendario from '../../Helpers/Calendario'
import Functions from '../../Helpers/Functions'
import { ScrollPanel } from 'primereact/scrollpanel';
import SinDatos from '../../Helpers/SinDatos';
import './FuncionesEnviadasPage.css';
import DownloadButton from '../../Helpers/Forms/DownloadButton';
import BotonComponent from '../../Helpers/Forms/BotonComponent';
import IconComponent from '../../Helpers/Forms/IconComponent';
import PaginationComponent from '../../Helpers/Forms/PaginationComponent.js';
import CalendarSvg from '../../../../assets/images/Calendar.svg';
import ClockSvg from '../../../../assets/images/Clock.svg';
import StarSvg from '../../../../assets/images/Star.svg';
import PinSvg from '../../../../assets/images/Pin.svg';
import NoMapSvg from '../../../../assets/images/NoMap.svg';
import SvgComponent from '../../Helpers/SvgComponent';

class FuncionesEnviadasPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            desdeHasta: ['', ''],
            funcionesEnviadas: [],
            cargarFuncionesEnviadas: true,
            notificarFiltros: true
        }

        this.fetchDataComponent = React.createRef();
        this.PintarPosicion = this.PintarPosicion.bind(this);
    }

    ValidarFechas() {
        var datFI = new Date(1980, 1, 1);
        var datFF = new Date(1980, 1, 1);
        var retorno = false;

        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        // Valida la fecha
        var tmpDia = Functions.isEmpty(desde) ? "" : desde.getDate();
        var tmpMes = Functions.isEmpty(desde) ? "" : desde.getMonth() + 1;
        var tmpAno = Functions.isEmpty(desde) ? "" : desde.getFullYear();
        var enaFI = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFI);

        tmpDia = Functions.isEmpty(hasta) ? "" : hasta.getDate();
        tmpMes = Functions.isEmpty(hasta) ? "" : hasta.getMonth() + 1;
        tmpAno = Functions.isEmpty(hasta) ? "" : hasta.getFullYear();
        var enaFF = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFF);

        if (!enaFI && !enaFF) {
            alert("Debe ingresar al menos una fecha para realizar el filtrado");
        }
        else if (enaFI && enaFF && (datFI > datFF)) {
            alert("La fecha final (hasta) debe ser mayor que la fecha inicial (desde)");
        } else {
            retorno = true;
        }

        return retorno;
    };

    createUrl(pagina) {
        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        const idUnidad = this.props.idUnidadSeleccionada;
        const FI = Functions.isEmpty(desde) ? "" : Functions.ToSQLDate(desde);
        const FF = Functions.isEmpty(hasta) ? "" : Functions.ToSQLDate(hasta);
        const finalUrl = process.env.REACT_APP_SERVICES_FUNCIONESENVIADAS
            + "IdUnidad=" + idUnidad
            + "&Page=" + pagina
            + "&FI=" + FI
            + "&FF=" + FF;
        return finalUrl;
    }

    createUrlCancelar(idFuncion) {
        const idUnidad = this.props.idUnidadSeleccionada;
        const finalUrl = process.env.REACT_APP_SERVICES_FUNCIONESENVIADASCANCELAR
            + "IdUnidad=" + idUnidad
            + "&IdFuncion=" + idFuncion
        return finalUrl;
    }

    VerFuncionesEnviadas_Click = (event) => {
        var validaciones = this.ValidarFechas();

        if (validaciones) {
            this.setState({ notificarFiltros: true });

            if (this.fetchDataComponent.current == null) {
                this.setState({ cargarFuncionesEnviadas: true });
            } else {
                const finalUrl = this.createUrl(0);
                this.fetchDataComponent.current.actualizarData(finalUrl);
            }
        }
    };

    onPageChange = (fetchComponent, newPage) => {
        var page = newPage - 1;
        const finalUrl = this.createUrl(page);
        fetchComponent.current.actualizarData(finalUrl);
    }

    refreshData = (fetchComponent) => {
        const finalUrl = this.createUrl(0);
        fetchComponent.current.actualizarData(finalUrl);
    }

    PintarPosicion = (unidad, posicion) => {
        this.props.PintarPosicion(unidad, posicion);
    }

    cancelarFuncion_Click = async (idFuncion) => {
        if (window.confirm("¿Está seguro que desea cancelar esta función?")) {
            var url = this.createUrlCancelar(idFuncion);

            // CONEXIÓN
            let response = await fetch(url);
            if (!response.ok)
                alert('Error enviando la cancelación a la unidad')
            let result = await response.text();

            if (result === "OK") {
                const finalUrl = this.createUrl(0);
                this.fetchDataComponent.current.actualizarData(finalUrl);
            } else {
                alert('Error procesando');
            }
        }
    };

    render() {
        let funcionesEnviadas = null;

        if (this.state.cargarFuncionesEnviadas) {
            const finalUrl = this.createUrl(0);
            var urlExportacionExcel = finalUrl + "&enExportacion=true";

            funcionesEnviadas = (
                <FetchData url={finalUrl} ref={this.fetchDataComponent}>
                    {data => {
                        if (this.state.notificarFiltros) {
                            this.props.VerMapaUltimaPosicion();
                            this.setState({ notificarFiltros: false });
                        }

                        var finalRender = (
                            <div>
                                <div className="PanelOperaciones">
                                    <BotonComponent value="Refrescar listado" onClick={(e) => this.refreshData(this.fetchDataComponent)} />
                                    &nbsp;&nbsp;
                                    <DownloadButton url={urlExportacionExcel} />
                                </div>
                                <Tabla posiciones={data} unidad={this.props.unidadSeleccionada} onPageChange={this.onPageChange} sender={this.fetchDataComponent} onClick={this.cancelarFuncion_Click} PintarPosicion={this.PintarPosicion} />
                            </div>)
                        return finalRender;                            
                        }
                    }
                </FetchData>
            );
        }

        let finalRender = (
            <div id="FuncionesEnviadasPage">
                <div className="FiltrosRow">
                    <Calendario placeholder="Seleccione un rango de fechas" id="CalendarioFuncionesEnviadas" desdeHasta={this.state.desdeHasta} onChange={(e) => this.setState({ desdeHasta: e.value })} />
                    <div className="input-group w-auto">
                        <div className="input-group-append">
                            <button className="btn btn-naranja" onClick={(e) => this.VerFuncionesEnviadas_Click(e)}>Filtrar</button>
                        </div>
                    </div>
                </div>

                {funcionesEnviadas}
            </div>
        );

        return finalRender;
    }
}

const Tabla = (props) => {
    const data = props.posiciones;
    const tieneFunciones = data.TieneFunciones;
    const funciones = data.Funciones;
    const pageSize = data.PageSize;
    const totalPages = data.TotalPages;
    const currentPage = data.CurrentPage;
    const totalRecords = pageSize * totalPages;

    var mensaje = "La unidad no posee ninguna función enviada para el periodo solicitado.";
    let finalRender = <div className="SinPosiciones"><SinDatos mensaje={mensaje} /></div>;

    if (tieneFunciones) {
        var renderFuncionEnviada = funciones.map(function (funcionEnviada, index) {
            return (
                <FuncionEnviada
                    key={index}
                    index={index}
                    unidad={props.unidad}
                    funcionEnviada={funcionEnviada}
                    onClick={props.onClick}
                    PintarPosicion={props.PintarPosicion} >
                </FuncionEnviada>
            )
        });

        finalRender = (
            <div>
                <div className="FuncionesEnviadas">
                    <ScrollPanel style={{ height: '100%' }} className="customScroll">
                        {renderFuncionEnviada}
                    </ScrollPanel>
                </div>

                <div className="Paginador">
                    <PaginationComponent
                        currentPage={currentPage}
                        totalRecords={totalRecords}
                        onChange={e => props.onPageChange(props.sender, e)}>
                    </PaginationComponent>
                </div>
            </div>
        );
    }

    return finalRender;
}

const FuncionEnviada = (props) => {
    var index = props.index;
    var funcionEnviada = props.funcionEnviada;
    var fecha = funcionEnviada.Fecha;
    var hora = funcionEnviada.Hora;
    var posicion = funcionEnviada.Posicion;
    var codigo = funcionEnviada.Codigo;
    var descripcionUnificado = funcionEnviada.Descripcion + ". " + Functions.Capitalize(funcionEnviada.DescripcionEstado)

    // RENDER CANCELABLE
    var renderCancelable = null;
    if (funcionEnviada.Cancelable) {
        renderCancelable = (
            <BotonComponent value="Cancelar envio" onClick={(e) => props.onClick(codigo)} />
        );
    }

    // RENDER POSICIÓN
    var renderPosicion = null;
    if (posicion === null) {
        renderPosicion = <IconComponent className="bg-rojo text-light" icono={NoMapSvg} />;
    } else {
        renderPosicion = <IconComponent className="bg-naranja text-light" icono={PinSvg} />;
    }

    return (
        <div className="FuncionEnviada" key={index} onMouseEnter={() => props.PintarPosicion(props.unidad, posicion)}>
            <div>
                <Fila icono={CalendarSvg} valor={fecha} />
                <Fila icono={ClockSvg} valor={hora} />
                <Fila icono={StarSvg} valor={descripcionUnificado} className="font-weight-bold" />
                {renderCancelable}
            </div>
            <div>
                {renderPosicion}
            </div>
        </div>
    );
};

const Fila = (props) => {
    var finalClassName = "FuncionValorRow " + (props.className == undefined ? "" : props.className);

    var renderFinal = (
        <div>
            <span className="FuncionIconoRow">
                <SvgComponent icono={props.icono} />
            </span>
            <span className={finalClassName}>
                {props.valor}
            </span>
        </div>
    );
    return renderFinal
};

export default FuncionesEnviadasPage;