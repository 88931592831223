import React from 'react';
import Pagination from "react-js-pagination";

const PaginationComponent = (props) => {
    var finalRender = (
        <Pagination
            activePage={props.currentPage + 1}
            itemsCountPerPage={20}
            totalItemsCount={props.totalRecords}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="‹"
            nextPageText="›"
            onChange={props.onChange}>
        </Pagination>
    );
    return finalRender
};

export default PaginationComponent;