import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './OverlayText.css';

const OverlayText = (props) => (
    <OverlayTrigger
        id={props.id}
        placement={props.placement}
        overlay={
            <Tooltip>{props.texto}</Tooltip>
        }
    >
        <div className="OverlayText">
            <div className="NombreUnidad">{props.texto1}</div>
            <div className="IdUnidad">{props.texto2}</div>
        </div>
    </OverlayTrigger>
);


//   const OverlayText = () => (
//     <OverlayTrigger
//       placement="right"
//       delay={{ show: 250, hide: 400 }}
//       overlay={renderTooltip}
//     >
//       <Button variant="success">Hover me to see</Button>
//     </OverlayTrigger>
//   );

export default OverlayText;