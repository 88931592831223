import React from 'react';
import IFrame from '../../Helpers/IFrame';
import Functions from '../../Helpers/Functions'

class MapaRecorrido extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            idUnidad: null,
            FI: null,
            FF: null
        }

        this.iframeComponent = React.createRef();
    }

    createUrl() {
        const idUnidad = Functions.isEmpty(this.state.idUnidad) ? "" : this.state.idUnidad;
        const FI = Functions.isEmpty(this.state.FI) ? "" : Functions.ToSQLDate(this.state.FI);
        const FF = Functions.isEmpty(this.state.FF) ? "" : Functions.ToSQLDate(this.state.FF);

        const finalUrl = process.env.REACT_APP_SERVICES_MAPARECORRIDO
            + "IdUnidad=" + idUnidad
            + "&FI=" + FI
            + "&FF=" + FF;
        return finalUrl;
    }

    FiltrarMapaRecorrido(idUnidad, FIParameter, FFParameter) {
        this.setState({ idUnidad: idUnidad, FI: FIParameter, FF: FFParameter });
    }

    MarcarPuntoRecorrido(timeStamp) {
        this.iframeComponent.current.MarcarPuntoRecorrido(timeStamp);
    }

    DesmarcarPuntoRecorrido(timeStamp) {
        this.iframeComponent.current.DesmarcarPuntoRecorrido(timeStamp);
    }

    render() {
        const finalUrl  = this.createUrl();
        var iframeRender  = <IFrame src={finalUrl} ref={this.iframeComponent} />

        let finalRender = (
            <div id="MapaRecorrido">
                {iframeRender}
            </div>
        );

        return finalRender;
    }
}

export default MapaRecorrido;