import React from 'react';
import axios from 'axios';

class ConnectComponent extends React.Component {
    componentDidMount() {
        //console.log("Connect. componentDidMount")
        this.getData(this.props.url);
    }

    async getData(url) {
        const response = await axios.get(this.props.url);
        this.props.onData(response.data);
    }

    render() {
        return null;
    }
}

export default ConnectComponent;
