import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

const ProgressBar = (props) => {
    const showProgressBar = props.showProgressBar;
    const message = " " + (props.message == null ? "Cargando..." : props.message);
    let finalRender;

    if (showProgressBar) {
        finalRender = (
            <div className="d-flex justify-content-center">
                <Button disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner>
                    <span>{message}</span>
                </Button>
            </div>
        );
    } else {
        finalRender = props.children;
    }

    return finalRender;
};

export default ProgressBar;