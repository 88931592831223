import React from 'react';
import SvgComponent from '../SvgComponent';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

class TooltipComponent extends React.Component {
    render() {
        var index = this.props.index;
        var descripcion = this.props.descripcion;
        var icono = this.props.icono;
        var className = "Icono btn btn-sm " + this.props.className;

        var finalRender = (
            <OverlayTrigger key={index} placement="left" overlay={this.renderTooltip(index, descripcion)}>
                <button className={className}>
                    <SvgComponent icono={icono} />
                </button>
            </OverlayTrigger>
        );

        return finalRender;
    }

    renderTooltip(index, descripcion) {
        return <Tooltip index={index}>{descripcion}</Tooltip>;
    }
};

export default TooltipComponent;