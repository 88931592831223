import React from 'react';
import { Calendar } from 'primereact/calendar';
import Functions from '../Helpers/Functions';

class Calendario extends React.Component {
    constructor(props) {
        super(props)

        this.yaOcurrioEventoSelect = false;
        this.calendarComponent = React.createRef();
    }

    onSelect = (e) => {
        this.yaOcurrioEventoSelect = true;
    }

    onChange = (e) => {
        // if (this.yaOcurrioEventoSelect) {
        //     var arrayLength = e.value.length;
        //     if (arrayLength == 2) {
        //         var a = this.calendarComponent.current;
        //         a.blur();
        //         //a.inputElement.parentElement.focus();
        //     }
        //     console.log();
        // } else {
        //     console.log("no evaluar")
        // }
        // console.log(e.value);
        this.props.onChange(e);
    }

    render() {
        let es = {
            firstDayOfWeek: 1,
            dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: 'Hoy',
            clear: 'Limpiar',
            dateFormat: 'dd/M/yy',
            weekHeader: 'Sm'
        };

        var desdeHasta = this.props.desdeHasta;
        var newDesdeHasta = null;

        if (!Functions.isEmpty(desdeHasta)) {
            if (!Functions.isEmpty(desdeHasta[0]) || !Functions.isEmpty(desdeHasta[1])) {
                newDesdeHasta = [desdeHasta[0], desdeHasta[1]]
            }
        }

        var finalRender = (
            <Calendar
                ref={this.calendarComponent}
                id={this.props.id}
                name={this.props.id}
                value={newDesdeHasta}
                showTime={false}
                readOnlyInput={true}
                selectionMode="range"
                dateFormat="dd/M/yy"
                placeholder={this.props.placeholder}
                locale={es}
                onSelect={this.onSelect}
                onChange={this.onChange}>
            </Calendar>
        );
        return finalRender
    }
}

export default Calendario;