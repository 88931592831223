import React from 'react';
import IFrame from '../../Helpers/IFrame';

class MapaTodasMisUnidades extends React.Component {
  constructor(props) {
    super(props)

    this.iframeComponent = React.createRef();
  }

  createUrl() {
    const finalUrl = process.env.REACT_APP_SERVICES_MAPAUNIDADES;
    return finalUrl;
  }

  PintarUnidades(unidades) {
    this.iframeComponent.current.PintarUnidades(unidades);
  }

  MarcarUnidad(unidad) {
    this.iframeComponent.current.MarcarUnidad(unidad.IdUnidad);
  }

  DesmarcarUnidad(unidad) {
    this.iframeComponent.current.DesmarcarUnidad(unidad.IdUnidad);
  }

  render() {
    // IFRAME
    const finalUrl = this.createUrl();
    var iframeRender = <IFrame src={finalUrl} ref={this.iframeComponent} />

    let finalRender = (
      <div id="MapaTodasMisUnidades">
        {iframeRender}
      </div>
    );

    return finalRender;
  }
}

export default MapaTodasMisUnidades;