import React from 'react';
import Functions from '../Helpers/Functions';

class IFrame extends React.Component {
  constructor(props) {
    super(props);

    this.iframeElement = null;

    this.state = {
      nombreFuncion: "",
      param1: null,
      param2: null
    };

    // ARREGLO QUE CONTIENE CADA UNA DE LAS FUNCIONES QUE SE EJECUTAN EN LOS MAPAS
    // ESTE ARREGLO SE HACE PARA EVITAR COLISIONES AL MOMENTO DE DESMONTAR EL COMPONENTE
    // PUEDEN EXISTIR 2, 3, 4 FUNCIONES ESPERANDO PARA SER EJECUTADAS (setInterval)
    this.funcionesMapas = window["FuncionesMapas"] = [];
  }

  componentWillUnmount() {
    // LIMPIAR LOS setInterval DE TODAS LAS FUNCIONES DE LOS MAPAS
    for (var item in this.funcionesMapas) {
      clearInterval(this.funcionesMapas[item]);
    }
  }

  IsDevelopment() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  }

  IsProduction() {
    return !this.IsDevelopment();
  }

  RetryFunction(nombreFuncion, funcion) {
    console.log("RetryFunction. Ingrese. Funcion: " + nombreFuncion);

    var isOnline = Functions.IsOnline();

    // ESTA EN AMBIENTE DE PRODUCCIÓN Y SE TIENE ACCESO A INTERNET
    if (this.IsProduction() && isOnline) {
      // RENDERIZAR. ACTUALIZAR EL COMPONENTE
      this.forceUpdate();

      try {
        // VERIFICAR SI SE PUEDE EJECUTAR LA FUNCIÓN PASADA POR PARÁMETRO
        // 1 PASO. LOADED DEL DOM (readyState === 'complete')
        // 2 PASO. LOADED INTERNO DE LOS MAPAS (contentWindow.MapsLoaded())
        var ejecucionExitosa = Functions.checkIframeLoaded(this.iframeElement);

        if (ejecucionExitosa) {
          // EJECUTAR LA FUNCIÓN
          funcion();
        } else {
          console.log("RetryFunction. Ejecucion NO exitosa. Funcion: " + nombreFuncion);

          // SI LA EJECUCIÓN NO ES EXITOSA, ESPERAR UN TIEMPO Y REINTENTAR
          this.funcionesMapas[nombreFuncion] = setTimeout(() => this.RetryFunction(nombreFuncion, funcion), 300);
        }
      } catch (error) {
        console.log("RetryFunction. Funcion: " + nombreFuncion + ". Error: " + error.message);

        // SI LA EJECUCIÓN GENERÓ UNA EXCEPCIÓN, ESPERAR UN TIEMPO Y REINTENTAR
        this.funcionesMapas[nombreFuncion] = setTimeout(() => this.RetryFunction(nombreFuncion, funcion), 300);
      }
    }
  }

  // EVENTOS MAPA DE TODAS MIS UNIDADES
  PintarUnidades(unidades) {
    this.RetryFunction("PintarUnidades", () => this.iframeElement.contentWindow.PintarUnidades(unidades));
  }

  MarcarUnidad(idUnidad) {
    this.RetryFunction("MarcarUnidad", () => this.iframeElement.contentWindow.MarcarUnidad(idUnidad));
  };

  DesmarcarUnidad(idUnidad) {
    this.RetryFunction("DesmarcarUnidad", () => this.iframeElement.contentWindow.DesmarcarUnidad(idUnidad));
  };

  // EVENTOS MAPA DE POSICIÓN
  PintarPosicion(unidad, posicion) {
    this.RetryFunction("PintarPosicion", () => this.iframeElement.contentWindow.PintarPosicion(unidad, posicion));
  }

  // EVENTOS MAPA DE RECORRIDO
  MarcarPuntoRecorrido(timeStamp) {
    this.RetryFunction("MarcarPuntoRecorrido", () => this.iframeElement.contentWindow.MarcarPuntoRecorridoAgrupado(timeStamp));
  }

  DesmarcarPuntoRecorrido(timeStamp) {
    this.RetryFunction("DesmarcarPuntoRecorrido", () => this.iframeElement.contentWindow.DesmarcarPuntoRecorridoAgrupado(timeStamp));
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    var isOnline = Functions.IsOnline();
    if (!isOnline)
        return (<div className="fade alert alert-danger show m-3 float-right">Verifique su conexión a Internet</div>)

    if (this.IsDevelopment())
      return <div>URL TO LOAD: {this.props.src}</div>

    return <iframe src={this.props.src} className="iframeMapa" name="mapaIframe" frameBorder="0" ref={el => this.iframeElement = el} />;
  }

}

export default IFrame;