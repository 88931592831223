import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ProgressBar = (props) => {
    var finalRender = (
        <Alert variant="danger">
            {props.mensaje}
        </Alert>
    );

    return finalRender;
};

export default ProgressBar;
