import React from 'react';
import MapaTodasMisUnidades from './Unidades/Mapas/MapaTodasMisUnidades';
import MapaUltimaPosicion from './Unidades/Mapas/MapaUltimaPosicion';
import MapaRecorrido from './Unidades/Mapas/MapaRecorrido';

class MapaController extends React.Component {
    constructor(props) {
        super(props)

        this.mapaTodasUnidadesComponent = React.createRef();
        this.mapaPosicionComponent = React.createRef();
        this.mapaRecorridoComponent = React.createRef();
    }

    // EVENTOS MAPA DE TODAS MIS UNIDADES
    PintarUnidades(unidades) {
        this.mapaTodasUnidadesComponent.current.PintarUnidades(unidades);
    }

    MarcarUnidad(unidad) {
        this.mapaTodasUnidadesComponent.current.MarcarUnidad(unidad);
    }

    DesmarcarUnidad(unidad) {
        this.mapaTodasUnidadesComponent.current.DesmarcarUnidad(unidad);
    }

    // EVENTOS MAPA DE POSICIÓN
    PintarPosicion(unidad, posicion) {
        this.mapaPosicionComponent.current.PintarPosicion(unidad, posicion);
    }

    // EVENTOS MAPA DE RECORRIDO
    FiltrarMapaRecorrido(idUnidad, FIParameter, FFParameter) {
        this.mapaRecorridoComponent.current.FiltrarMapaRecorrido(idUnidad, FIParameter, FFParameter);
    }

    MarcarPuntoRecorrido(timeStamp) {
        this.mapaRecorridoComponent.current.MarcarPuntoRecorrido(timeStamp);
    }

    DesmarcarPuntoRecorrido(timeStamp) {
        this.mapaRecorridoComponent.current.DesmarcarPuntoRecorrido(timeStamp);
    }

    render() {
        let finalRender = (
            <div id="MapaController">
                {this.renderMapa()}
            </div>
        );

        return finalRender;
    }

    renderMapa() {
        var tipoMapa = this.props.tipoMapaSeleccionado;
        let finalRender;

        if (tipoMapa === 1) {
            finalRender = <MapaTodasMisUnidades {...this.props} ref={this.mapaTodasUnidadesComponent} />
        } else if (tipoMapa === 2) {
            finalRender = <MapaUltimaPosicion {...this.props} ref={this.mapaPosicionComponent} />
        } else if (tipoMapa === 3) {
            finalRender = <MapaRecorrido {...this.props} ref={this.mapaRecorridoComponent} />
        }

        return finalRender;
    }
}

export default MapaController;