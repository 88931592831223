import React from 'react';
import IFrame from '../../Helpers/IFrame';

class GeocercasPage extends React.Component {
    createUrl() {
        const idUnidad = this.props.idUnidadSeleccionada;
        const finalUrl = process.env.REACT_APP_SERVICES_GEOCERCASXUNIDAD
            + "IdUnidad=" + idUnidad
        return finalUrl;
    }

    render() {
        var finalUrl = this.createUrl();
        var finalRender = (
            <div id="GeocercasPage">
                <IFrame src={finalUrl} />
            </div>
        );

        return finalRender;
    }
}

export default GeocercasPage;