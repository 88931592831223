import React from 'react';
import IFrame from '../../Helpers/IFrame';
import { Grid, Segment, Image, Container, Form, Input, Divider, TextArea, Dropdown, Label, Checkbox } from 'semantic-ui-react';
import './CaracteristicasPage.css';
import ExplicacionFormulario from '../../Helpers/Forms/ExplicacionFormulario';
import TextboxComponent from '../../Helpers/Forms/TextboxComponent';
import CheckboxComponent from '../../Helpers/Forms/CheckboxComponent';
import DropdownComponent from '../../Helpers/Forms/DropdownComponent';
import DateFormat from '../../Helpers/DateFormat';
import Moment from 'react-moment';
import moment from 'moment-timezone';

class CaracteristicasPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            informacionUnidad: null,
            editarValorOdometroEstimadoKm: false
        }
    }

    getData() {
        var finalUrl = process.env.REACT_APP_SERVICES_INFOUNIDAD + "IdUnidad=" + this.props.unidadSeleccionada.IdUnidad;

        fetch(finalUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("not ok");
                }
                return response.json();
            })
            .then((result) => {
                this.setState({ informacionUnidad: result });
            })
            .catch((err) => {
                console.info("error fetch. " + err);
            });
    }

    componentDidMount() {
        this.getData();
    };

    GuardarInfoUnidad = async () => {
        var finalUrl = process.env.REACT_APP_SERVICES_INFOUNIDADGUARDAR;

        // CONEXIÓN
        const response = await fetch(finalUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(this.state.informacionUnidad)
        });

        if (!response.ok)
            alert('Error guardando la información de la unidad')
        const result = await response.text();

        if (result === "OK") {
            alert('Actualización exitosa');
            this.props.CerrarEditarUnidad();
        } else {
            alert('Error procesando. ' + result);
        }
    }

    render() {
        if (this.state.informacionUnidad == null)
            return null;

        var finalRender = (
            <div id="CaracteristicasPage">
                <ExplicacionFormulario valor="A continuación se muestran todos los datos de la cuenta. Realice los cambios que requiera y presione el botón “guardar cambios”. Si no desea almacenar cambios, presione el botón “Cancelar”." />
                <Divider />

                <div className="Formulario">
                    <Form>
                        <Grid divided padded>
                            <Grid.Column width={10}>
                                <Grid columns='equal' celled='internally'>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            {this.renderTablaPrincipalLadoIzquierdo()}
                                        </Grid.Column>

                                        <Grid.Column width={8}>
                                            {this.renderTablaPrincipalLadoDerecho()}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Subtitulo valor="Administración" />

                                            <TablaAdministracion data={this.state} {...this.props} />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Subtitulo valor="Notificaciones" />

                                            <TablaNotificaciones data={this.state} {...this.props} />
                                        </Grid.Column>

                                        <Grid.Column>
                                            <Subtitulo valor="Comportamiento" />

                                            <TablaComportamiento data={this.state} {...this.props} />
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                            </Grid.Column>

                            {/* LADO DERECHO */}
                            <Grid.Column width={6}>
                                <Subtitulo valor="Geocerca de seguridad (apagado de motor)" />
                                <ExplicacionFormulario valor="Cuando la plataforma detecte que la unidad se encuentra “fuera” de esta geocerca, enviará un comando de “apagado de motor”. Si no requiere la funcionalidad para esta unidad, seleccione “ninguna”" />

                                <p>&nbsp;</p>
                               
                               

                                {/* <p>&nbsp;</p>
                            <Subtitulo valor="Notas distribuidor" />
                            <TextArea
                                valor={this.state.informacionUnidad.NotasDistribuidor}
                                onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotasDistribuidor: e.target.value } })}>
                            </TextArea>

                            <p>&nbsp;</p>
                            <Subtitulo valor="Notas administración" />
                            <TextArea
                                valor={this.state.informacionUnidad.NotasAdministrador}
                                onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotasAdministrador: e.target.value } })}>
                            </TextArea> */}

                                <p>&nbsp;</p>
                                <Subtitulo valor="Notificaciones por kilometraje" />
                                {this.renderNotificacionesPorKilometraje()}

                                <p>&nbsp;</p>
                                <Subtitulo valor="Notificación de detención" />
                                {this.renderNotificacionesPorDetencion()}

                                <p>&nbsp;</p>
                                <button className="btn btn-naranja btn-block" onClick={this.GuardarInfoUnidad}>Guardar cambios sobre la unidad</button>
                                <button className="btn btn-rojo btn-block" onClick={this.props.CerrarEditarUnidad}>Cancelar opción</button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </div>
            </div >
        );

        return finalRender;
    }

    renderTablaPrincipalLadoIzquierdo() {
        var numeroId = this.state.informacionUnidad.StrUnidad;
        var idCuenta = this.state.informacionUnidad.IdCuenta;
        var descripcion = this.state.informacionUnidad.Descripcion;
        var marca = this.state.informacionUnidad.Marca;
        var placa = this.state.informacionUnidad.Placa;
        var esn = this.state.informacionUnidad.Esn;
        var sim = this.state.informacionUnidad.NroSim;
        var esAdmin = this.props.usuarioActual.Tipo !== 1;

        var finalRender = (
            <div>
                <ClaveValor label="Número id" valor={numeroId} readOnly={true} />
                <ClaveValorDropdown label="Cuenta" metadata={this.state.informacionUnidad.CuentasDisponibles} valor={idCuenta} readOnly={esAdmin} />
                <ClaveValor label="Descripción"
                    valor={descripcion}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Descripcion: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Marca"
                    valor={marca}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Marca: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Placa"
                    valor={placa}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Placa: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Esn unidad"
                    valor={esn}
                    readOnly={esAdmin}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Esn: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Número SIM Card" valor={sim} readOnly={esAdmin} />
            </div>
        );

        return finalRender;
    }


    renderTablaPrincipalLadoDerecho() {
        var imei = this.state.informacionUnidad.Imei;
        var modelo = this.state.informacionUnidad.Modelo;
        var color = this.state.informacionUnidad.Color;
        var serial = this.state.informacionUnidad.Serial;
        var pool = this.state.informacionUnidad.Pool;
        var nroContenedor = this.state.informacionUnidad.NroContenedor;
        var esAdmin = this.props.usuarioActual.Tipo !== 1;

        var finalRender = (
            <div>
                <ClaveValor label="IMEI" valor={imei} readOnly={esAdmin} />
                <ClaveValor label="Modelo"
                    valor={modelo}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Modelo: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Color"
                    valor={color}
                    onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, Color: e.target.value } })}>
                </ClaveValor>
                <ClaveValor label="Nro. serial vehículo" valor={serial} readOnly={esAdmin} />
                <ClaveValor label="Pool SIM" valor={pool} readOnly={esAdmin} />
                <ClaveValor label="#Contenedor" valor={nroContenedor} readOnly={esAdmin} />
            </div>
        );

        return finalRender;
    }

    renderNotificacionesPorKilometraje() {
        var permitirNotificaciones = this.state.informacionUnidad.PermitirEdicionNotificacionesKilometraje;
        var motivoNoPermitirNotificaciones = this.state.informacionUnidad.MotivoNoPermitirEdicionNotificacionesKilometraje;
        var notificacionesActivas = this.state.informacionUnidad.NotificacionesKilometrajeActivas;
        var valorOdometroIngresadoKm = this.state.informacionUnidad.ValorOdometroIngresadoKm;
        var cicloNotificacionesKm = this.state.informacionUnidad.CicloNotificacionesKm;
        var fechaUltimaNotificacionKilometraje = this.state.informacionUnidad.FechaUltimaNotificacionKilometraje;
        var ultimoKilometrajeNotificado = this.state.informacionUnidad.UltimoKilometrajeNotificado;
        var fechaActualizacionUltimoOdometroReal = this.state.informacionUnidad.FechaActualizacionUltimoOdometroReal;
        var valorOdometroEstimadoKm = this.state.informacionUnidad.ValorOdometroEstimadoKm;
        var sugerirValidarOdometro = this.state.informacionUnidad.SugerirValidarOdometro;
        var ciclos = this.state.informacionUnidad.OfertaCiclosKilometraje;
        
        var modoEdicion = fechaActualizacionUltimoOdometroReal !== '0001-01-01T00:00:00';
        if (cicloNotificacionesKm == 0) {
            this.setState({ informacionUnidad: { ...this.state.informacionUnidad, CicloNotificacionesKm: 10000} })
        }
        var editarValorOdometroEstimadoKm = this.state.editarValorOdometroEstimadoKm;

        var renderSoportaOdometro;
        if (permitirNotificaciones) {
            renderSoportaOdometro = <CheckboxComponent label="Quiero recibir notificaciones cuando la unidad alcance un ciclo de kilómetros" 
                                        checked={notificacionesActivas}
                                        onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotificacionesKilometrajeActivas: !notificacionesActivas } })} />
        } else {
            renderSoportaOdometro = <span className="Label">{motivoNoPermitirNotificaciones}</span>
        }

        var renderSeHanEnviadoNotificaciones;
        if (ultimoKilometrajeNotificado != 0) {
            renderSeHanEnviadoNotificaciones = <div>🔔 La última notificación enviada fue el {<Moment format="D-MMM-YYYY h:mm:ss A" interval={0}>{fechaUltimaNotificacionKilometraje}</Moment>} cuando la unidad llegó a {ultimoKilometrajeNotificado} km.</div>
        } else {
            renderSeHanEnviadoNotificaciones = <div>🔔 No se han enviado notificaciones de kilometraje.</div>
        }

        var renderTodasNotificaciones;
        if (modoEdicion && notificacionesActivas) {
            renderTodasNotificaciones = (
                <div style={{fontSize: '12px', padding: '5px 0 10px 25px'}}>
                    {renderSeHanEnviadoNotificaciones}
                    <div>💾 La última actualización manual del cuentakilómetros fue el {<Moment format="D-MMM-YYYY" interval={0}>{fechaActualizacionUltimoOdometroReal}</Moment>}.</div>
                    <div>{sugerirValidarOdometro ? "💡 Se sugiere actualizar actualizar en plataforma el valor actual del cuentakilómetros." : ""}</div>
                </div>
            );
        }

        var renderIngresarOdometro;
        if (modoEdicion && !editarValorOdometroEstimadoKm) {
            renderIngresarOdometro = (
                <div className='w-100 d-flex justify-content-between align-items-baseline bg-white'> 
                    <span>{valorOdometroEstimadoKm}&nbsp;km</span>
                    <button className="btn btn-info" onClick={(e) => this.setState({ editarValorOdometroEstimadoKm: !this.state.editarValorOdometroEstimadoKm} )}>
                        Actualizar
                    </button>
                </div>
            );
        } else {
            renderIngresarOdometro = <Input id="ValorOdometroIngresadoKm" value={valorOdometroIngresadoKm} type="number" min="0" max="10000000" 
                                            label={{ content: 'km' }} labelPosition='right'
                                            onChange={(e, data) => {
                                                var value = data.value.slice(0, 7);
                                                this.setState({ informacionUnidad: { ...this.state.informacionUnidad, ValorOdometroIngresadoKm: value} })
                                            }}>
                                    </Input>
        }

        var renderNotificacionesActivas;
        if (notificacionesActivas) {
            renderNotificacionesActivas = (
                <div>
                    <div className="field d-flex justify-content-between align-items-baseline my-2">
                        <label style={{width: '310px'}} className="mr-2" htmlFor="ValorOdometroIngresadoKm">{(!modoEdicion || editarValorOdometroEstimadoKm) ? "Ingrese el valor actual del cuentakilómetros:" : "Cuentakilómetros estimado:" }</label>
                        {renderIngresarOdometro}
                    </div>
    
                    <div className="field d-flex justify-content-between align-items-baseline">
                        <label style={{width: '310px'}} className="mr-2" htmlFor="CicloNotificacionesKm">Recibir notificaciones: </label>
                        <DropdownComponent id="CicloNotificacionesKm"
                                            metadata={ciclos} 
                                            valor={cicloNotificacionesKm}
                                            onChange={(e, data) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, CicloNotificacionesKm: data.value} })} />
                    </div>
                </div>
            );
        }

        var renderExplicacionCiclo;
        if (notificacionesActivas) {
            var sobrante = valorOdometroIngresadoKm % cicloNotificacionesKm;
            var cicloAnterior = valorOdometroIngresadoKm - sobrante;
            var siguienteCiclo = cicloAnterior + (cicloNotificacionesKm * 1);
            var siguienteCiclo2 = cicloAnterior + (cicloNotificacionesKm * 2);
            var siguienteCiclo3 = cicloAnterior + (cicloNotificacionesKm * 3);

            renderExplicacionCiclo = <div className='mt-3'>💡 Las notificaciones serán a los {siguienteCiclo} km, {siguienteCiclo2} km, {siguienteCiclo3} km y así sucesivamente.</div>
        }
    
        var finalRender = (
            <div>
                {renderSoportaOdometro}
                {renderTodasNotificaciones}
                {renderNotificacionesActivas}
                {renderExplicacionCiclo}
            </div>
        );
    
        return finalRender;
    }

    renderNotificacionesPorDetencion() {
        var activarNotificaciones = this.state.informacionUnidad.NotificacionesDetencionActivas;
        var activarLunes = this.state.informacionUnidad.NotificacionesDetencionLunes;
        var activarMartes = this.state.informacionUnidad.NotificacionesDetencionMartes;
        var activarMiercoles = this.state.informacionUnidad.NotificacionesDetencionMiercoles;
        var activarJueves = this.state.informacionUnidad.NotificacionesDetencionJueves;
        var activarViernes = this.state.informacionUnidad.NotificacionesDetencionViernes;
        var activarSabado = this.state.informacionUnidad.NotificacionesDetencionSabado;
        var activarDomingo = this.state.informacionUnidad.NotificacionesDetencionDomingo;
        
        var renderActivarNotificaciones = <CheckboxComponent label="Activar notificación de detención" 
        checked={activarNotificaciones}
        onChange={(e) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotificacionesDetencionActivas: !activarNotificaciones } })} />

        var renderNotificacionesActivas = (
            <div>
                <div><b>Días y franja horaria a monitorear</b></div>

                <div className="NotificacionPorDetencionDias">
                    <CheckboxComponent label="L" checked={activarLunes} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="M" checked={activarMartes} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="W" checked={activarMiercoles} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="J" checked={activarJueves} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="V" checked={activarViernes} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="S" checked={activarSabado} readOnly={!activarNotificaciones} />
                    <CheckboxComponent label="D" checked={activarDomingo} readOnly={!activarNotificaciones} />
                </div>

                <div className="NotificacionPorDetencionDesdeHasta my-2">
                    <label className="font-weight-bold">Desde:&nbsp;</label>
                    <div className="NotificacionPorDetencionDesde">
                        <DropdownComponent
                            disabled={!activarNotificaciones}
                            metadata={this.state.informacionUnidad.OfertaCiclosKilometraje}
                            valor={this.state.informacionUnidad.NotificacionesDetencionDesdeHora}
                            onChange={(e, { value }) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotificacionesDetencionDesdeHora: value } })}>
                        </DropdownComponent>
                    </div>
                    <label className="font-weight-bold">Hasta:</label>
                    <div className="NotificacionPorDetencionHasta">
                        <DropdownComponent
                            disabled={!activarNotificaciones}
                            metadata={this.state.informacionUnidad.OfertaCiclosKilometraje}
                            valor={this.state.informacionUnidad.NotificacionesDetencionHastaHora}
                            onChange={(e, { value }) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, NotificacionesDetencionHastaHora: value } })}>
                        </DropdownComponent>
                    </div>
                </div>

                <div className="ClaveValor my-2">
                    <span className="Label">
                        <label>Notificar después de:&nbsp;</label>
                    </span>
                    <div>
                        <DropdownComponent
                            disabled={!activarNotificaciones}
                            metadata={this.state.informacionUnidad.GeocercasDisponibles}
                            valor={this.state.informacionUnidad.NotificacionesDetencionDespuesDe}
                            onChange={(e, { value }) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, IdGeocercaSeguridad: value } })}>
                        </DropdownComponent>
                    </div>
                </div>

                <div className="ClaveValor my-2">
                    <span className="Label">
                        <label>Reenviar notificación cada:&nbsp;</label>
                    </span>
                    <span>
                        <DropdownComponent
                            disabled={!activarNotificaciones}
                            metadata={this.state.informacionUnidad.GeocercasDisponibles}
                            valor={this.state.informacionUnidad.NotificacionesDetencionDespuesDe}
                            onChange={(e, { value }) => this.setState({ informacionUnidad: { ...this.state.informacionUnidad, IdGeocercaSeguridad: value } })}>
                        </DropdownComponent>
                    </span>
                </div>

                <div>
                    Summary
                </div>
            </div>
        );
       
        var finalRender = (
            <div>
                {renderActivarNotificaciones}
                {renderNotificacionesActivas}
            </div>
        );
    
        return finalRender;
    }    
}

const TablaAdministracion = (props) => {
    var fechaVenta = props.data.informacionUnidad.FechaVenta;
    var fechaInstalacion = props.data.informacionUnidad.FechaInstalacion;
    var idPaquete = props.data.informacionUnidad.IdPaquete;
    var idEstado = props.data.informacionUnidad.IdEstado;
    var esAdmin = props.usuarioActual.Tipo !== 1;

    fechaVenta = new Date(fechaVenta);
    fechaVenta = fechaVenta.getDate() + "/" + (fechaVenta.getMonth() + 1) + "/" + fechaVenta.getFullYear();
    fechaInstalacion = new Date(fechaInstalacion);
    fechaInstalacion = fechaInstalacion.getDate() + "/" + (fechaInstalacion.getMonth() + 1) + "/" + fechaInstalacion.getFullYear();

    var finalRender = (
        <div>
            <ClaveValor label="Fecha venta" valor={fechaVenta} readOnly={esAdmin} />
            <ClaveValor label="Fecha instalación" valor={fechaInstalacion} readOnly={esAdmin} />
            <ClaveValorDropdown label="Paquete" metadata={props.data.informacionUnidad.PaquetesDisponibles} valor={idPaquete} readOnly={esAdmin} />
            <ClaveValorDropdown label="Estado unidad" metadata={props.data.informacionUnidad.EstadosDisponibles} valor={idEstado} readOnly={esAdmin} />
        </div>
    );

    return finalRender;
}

const TablaNotificaciones = (props) => {
    var botonPanico = props.data.informacionUnidad.Notificaciones.BotonPanico;
    var limiteVelocidadViolado = props.data.informacionUnidad.Notificaciones.LimiteVelocidadViolado;
    var energiaPrincipalCortada = props.data.informacionUnidad.Notificaciones.EnergiaPrincipalCortada;
    var ignicionEncendida = props.data.informacionUnidad.Notificaciones.IgnicionEncendida;
    var ignicionApagada = props.data.informacionUnidad.Notificaciones.IgnicionApagada;
    var vehiculoRemolcado = props.data.informacionUnidad.Notificaciones.VehiculoRemolcado;
    var puertaAbierta = props.data.informacionUnidad.Notificaciones.PuertaAbierta;
    var enganche = props.data.informacionUnidad.Notificaciones.Enganche;
    var desenganche = props.data.informacionUnidad.Notificaciones.Desenganche;
    var esAdmin = props.usuarioActual.Tipo === 1;

    var finalRender = (
        <div className="CheckBoxContainer">
            <CheckboxComponent label="Botón de pánico" checked={botonPanico} readOnly={esAdmin} />
            <CheckboxComponent label="Límite de velocidad violado" checked={limiteVelocidadViolado} readOnly={esAdmin} />
            <CheckboxComponent label="Energía principal" checked={energiaPrincipalCortada} readOnly={esAdmin} />
            <CheckboxComponent label="Ignición apagada" checked={ignicionEncendida} readOnly={esAdmin} />
            <CheckboxComponent label="Vehículo remolcado" checked={vehiculoRemolcado} readOnly={esAdmin} />
            <CheckboxComponent label="Puerta abierta" checked={puertaAbierta} readOnly={esAdmin} />
            <CheckboxComponent label="Enganche" checked={enganche} readOnly={esAdmin} />
            <CheckboxComponent label="Desenganche" checked={desenganche} readOnly={esAdmin} />
        </div>
    );

    return finalRender;
}

const TablaComportamiento = (props) => {
    var AVLNormal = props.data.informacionUnidad.Comportamiento.AVLNormal;
    var geocercaSeguridad = props.data.informacionUnidad.Comportamiento.GeocercaSeguridad;
    var apagadoPorBotonPanico = props.data.informacionUnidad.Comportamiento.ApagadoPorBotonPanico;

    var finalRender = (
        <div className="CheckBoxContainer">
            <CheckboxComponent label="Geocerca de seguridad" checked={AVLNormal} />
            <CheckboxComponent label="AVL normal" checked={geocercaSeguridad} />
            <CheckboxComponent label="Apagado por botón de pánico" checked={apagadoPorBotonPanico} />
        </div>
    );

    return finalRender;
}

const Subtitulo = (props) => {
    var finalRender = (
        <div className="Subtitulo">{props.valor.toUpperCase()}</div>
    );

    return finalRender;
}


const ClaveValor = (props) => {
    var id = props.label;
    var label = props.label + ":";
    var readOnly = props.readOnly === undefined ? false : props.readOnly;

    return (
        <div className="ClaveValor">
            <span className="Label">
                <label htmlFor={id} >{label}</label>
            </span>
            <span className="Control">
                <Input id={id} value={props.valor} disabled={readOnly} maxLength="250" onChange={props.onChange} />
            </span>
        </div>
    );
};

const ClaveValorDropdown = (props) => {
    var id = props.label;
    var label = props.label + ":";
    var stateOptions = props.metadata.map(m => ({
        key: m.ID,
        text: m.Valor,
        value: m.ID
    }));

    var text = "";
    for (var index = 0; index < props.metadata.length; ++index) {
        var m = props.metadata[index];
        if (m.ID == props.valor) {
            text = m.Valor;
        }
    }

    return (
        <div className="ClaveValor">
            <span className="Label">
                <label htmlFor={id}>{label}</label>
            </span>
            <span className="Control">
                {/* <Dropdown options={stateOptions} text={text} /> */}
                <Input id={id} value={text} disabled={true} maxLength="250" />
            </span>
        </div>
    );
};

export default CaracteristicasPage;