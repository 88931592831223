import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Moment from 'react-moment';
import './StreetView.css';
import moment from 'moment-timezone';

class StreeView extends React.Component {
  suma(pos) {
    let suma = pos + 90;
    if (suma > 360) {
      suma = suma - 360;
    }
    return suma;
  };

  render() {
    var direccion1 = this.props.direccion;
    var direccion2 = this.suma(direccion1);
    var direccion3 = this.suma(direccion2);
    var direccion4 = this.suma(direccion3);
    var urlBase = process.env.REACT_APP_SERVICES_STREETVIEW + "lat=" + this.props.latitud + "&lng=" + this.props.longitud + "&dir=";

    // VELOCIDAD
    let velocidadNumero = this.props.velocidad;
    let velocidad = null;

    velocidadNumero = <div className="VelocidadNumero">{velocidadNumero}</div>
    velocidad = <div>km/h</div>

    // const pos = this.props.velocidad.indexOf(" ");
    // if (pos > 0) {
    //   velocidadNumero = <div className="VelocidadNumero">{this.props.velocidad.substr(0, pos)}</div>
    //   velocidad = <div>{this.props.velocidad.substr(pos + 1)}</div>
    // } else {
    //   velocidadNumero = <div className="VelocidadNumeroNoNumero">{this.props.velocidad}</div>;
    // }

    var fechaEnTimezoneMexico = moment.tz(this.props.fecha, "America/Mexico_City");

    return (
      <div className="StreetViewPanel">
        <div className="Antiguedad bg-rojo text-white">
          <Moment interval={10000} fromNow>
            {fechaEnTimezoneMexico}
          </Moment>
        </div>
        <div className="Velocidad bg-azul text-white">
          {velocidadNumero}
          {velocidad}
        </div>
        <Carousel interval={null} indicators={false} controls={false}>
          <Carousel.Item>
            <img src={urlBase + direccion1} className="d-block w-100" alt="StreetView posición 1"></img>
          </Carousel.Item>
          {/* <Carousel.Item>
            <img src={urlBase + direccion2} className="d-block w-100" alt="StreetView posición 2"></img>
          </Carousel.Item>
          <Carousel.Item>
            <img src={urlBase + direccion3} className="d-block w-100" alt="StreetView posición 3"></img>
          </Carousel.Item>
          <Carousel.Item>
            <img src={urlBase + direccion4} className="d-block w-100" alt="StreetView posición 4"></img>
          </Carousel.Item> */}
        </Carousel>
      </div>
    )
  }
}

export default StreeView;