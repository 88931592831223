import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment-timezone';

import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'semantic-ui-css/semantic.min.css';

import './components/app/App.css';
// import './components/app/CustomBoostrap.css';
import './components/app/CustomControls.css';

import App from './components/app/App';

// SETTINGS globales de Moment
Moment.globalLocale = 'es';
Moment.globalLocal = true;

ReactDOM.render(<Router basename="#"><App /></Router>, document.getElementById('root'));