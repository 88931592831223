import React from 'react';

class Functions extends React.Component {

    // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
    static timeoutPromise(ms, promise) {
        return new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject(new Error("timeout"))
            }, ms);

            promise.then(
                (res) => {
                    clearTimeout(timeoutId);
                    resolve(res);
                },
                (err) => {
                    clearTimeout(timeoutId);
                    reject(err);
                }
            );
        })
    }

    // Validate a date with controls entries
    static ValidateDateValues(Day, Month, Year, minYear, retDate) {
        // Construct the date to validate
        var dateVal = new Date(Year, Month - 1, Day);
        if (retDate !== null) {
            retDate.setFullYear(dateVal.getFullYear());
            retDate.setMonth(dateVal.getMonth());
            retDate.setDate(dateVal.getDate());
        }

        // The elements of JScript interpretated date must be equals to the user entries and the year must be higher minYear
        var result = ((Month === dateVal.getMonth() + 1)
            && (Day === dateVal.getDate())
            && (Year === dateVal.getFullYear())
            && Year >= minYear);

        return result;
    }

    // Calculate the days ellapsed between 2 dates
    static CalculateDays(From, To) {
        if (To >= From) {
            return (To - From) / (1000 * 60 * 60 * 24); //milisegundos, segundos, minutos, horas
        } else {
            return -1;
        }
    }

    // Enviar fechas al servidor (el estandar será este)
    static ToSQLDate(d) {
        return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    }

    // Capitalize
    // https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
    static Capitalize(s) {
        if (typeof s !== 'string')
            return ''

        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    // https://stackoverflow.com/questions/7378228/check-if-an-element-is-present-in-an-array/18101063#18101063
    static isInArray(value, array) {
        // return array.some(function (element) {
        //     if (value.toLowerCase() === element.toLowerCase()) {
        //         return true;
        //     }
        // });
        return array.indexOf(value) > -1;
    }

    // https://stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it/7394787#7394787
    static decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    static isEmpty(value) {
        var check = value == undefined || value == null || value === "" || value === '';
        return check;
    }

    // https://stackoverflow.com/questions/9249680/how-to-check-if-iframe-is-loaded-or-it-has-a-content
    static checkIframeLoaded(iframe) {
        // Get a handle to the iframe element
        var iframeDoc = iframe.contentDocument || iframe.contentWindow;

        // Check if loading is complete
        if (iframeDoc.readyState === 'complete') {
            if (iframe.contentWindow.MapsLoaded()) {
                return true;
            }
        }
        return false;
    }

    static IsOnline() {
        try {
            return navigator.onLine;
        } catch (error) {
            return true;
        }
    }

    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    static removeDiacritics(text) {
        var withoutDiacritics = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return withoutDiacritics;
    }
}

export default Functions;