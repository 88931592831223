import React from 'react';
import FetchData from '../../Helpers/FetchData';
import Calendario from '../../Helpers/Calendario'
import Functions from '../../Helpers/Functions'
import { ScrollPanel } from 'primereact/scrollpanel';
import SinDatos from '../../Helpers/SinDatos';
import DownloadButton from '../../Helpers/Forms/DownloadButton';
import PaginationComponent from '../../Helpers/Forms/PaginationComponent.js';
import OkSvg from '../../../../assets/images/Ok.svg';
import './RecorridoPage.css'
import TooltipComponent from '../../Helpers/Forms/TooltipComponent';

class RecorridoPage extends React.Component {
    constructor(props) {
        super(props)

        var hasta = new Date();
        var desde = new Date();
        desde.setDate(desde.getDate() - 1);
        var desdeHasta = [desde, hasta];

        this.state = {
            desdeHasta: desdeHasta,
            posiciones: [],
            notificarFiltros: true
        }

        this.fetchDataComponent = React.createRef();
    }

    VerRecorrido() {
        var datFI = new Date(1980, 1, 1);
        var datFF = new Date(1980, 1, 1);
        var maxDias = 3;
        var retorno = false;

        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        // Valida la fecha
        var tmpDia = Functions.isEmpty(desde) ? "" : desde.getDate();
        var tmpMes = Functions.isEmpty(desde) ? "" : desde.getMonth() + 1;
        var tmpAno = Functions.isEmpty(desde) ? "" : desde.getFullYear();
        var enaFI = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFI);

        tmpDia = Functions.isEmpty(hasta) ? "" : hasta.getDate();
        tmpMes = Functions.isEmpty(hasta) ? "" : hasta.getMonth() + 1;
        tmpAno = Functions.isEmpty(hasta) ? "" : hasta.getFullYear();
        var enaFF = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFF);

        if (!enaFI || !enaFF) {
            alert("La fecha inicial (desde) y la fecha final (hasta) son obligatorias y deben ser válidas");
        }
        else if (datFI > datFF) {
            alert("La fecha final (hasta) debe ser mayor que la fecha inicial (desde)");
        }
        // else if (Functions.CalculateDays(datFI, datFF) > maxDias) {
        //     alert("El periodo máximo en el mapa de recorrido es de " + maxDias + " días");
        // }
        else {
            retorno = true;
        }

        return retorno;
    };

    CreateUrl(pagina) {
        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        var idUnidad = this.props.idUnidadSeleccionada;
        var FI = Functions.isEmpty(desde) ? "" : Functions.ToSQLDate(desde);
        var FF = Functions.isEmpty(hasta) ? "" : Functions.ToSQLDate(hasta);
        var finalUrl = process.env.REACT_APP_SERVICES_RECORRIDOAGRUPADO
            + "IdUnidad=" + idUnidad
            + "&Page=" + pagina
            + "&FI=" + FI
            + "&FF=" + FF;
        return finalUrl;
    }

    FiltrarMapaRecorrido = () => {
        this.props.FiltrarMapaRecorrido(this.props.idUnidadSeleccionada, this.state.desdeHasta[0], this.state.desdeHasta[1]);
    }

    VerRecorrido_Click = (event) => {
        var validaciones = this.VerRecorrido();

        if (validaciones) {
            this.setState({ notificarFiltros: true });
            const finalUrl = this.CreateUrl(0);
            this.fetchDataComponent.current.actualizarData(finalUrl);
        }
    };

    onPageChange = (fetchComponent, newPage) => {
        var page = newPage - 1;
        const finalUrl = this.CreateUrl(page);
        fetchComponent.current.actualizarData(finalUrl);
    }

    render() {
        let posiciones = null;

        const finalUrl = this.CreateUrl(0);
        var urlExportacionExcel = finalUrl + "&enExportacion=true";
        urlExportacionExcel = urlExportacionExcel.replace("GetRecorridoAgrupado", "GetRecorrido");

        posiciones = (
            <FetchData url={finalUrl} ref={this.fetchDataComponent}>
                {data => {
                    if (this.state.notificarFiltros) {
                        this.props.FiltrarMapaRecorrido(this.props.idUnidadSeleccionada, this.state.desdeHasta[0], this.state.desdeHasta[1]);
                        this.setState({ notificarFiltros: false });
                    }

                    var finalRender = (
                        <div>
                            <div className="PanelOperaciones">
                                <DownloadButton url={urlExportacionExcel} />
                            </div>

                            <TablaPosicionesAgrupadas
                                data={data}
                                onPageChange={this.onPageChange}

                                FiltrarMapaRecorrido={this.FiltrarMapaRecorrido}
                                MarcarPuntoRecorrido={this.props.MarcarPuntoRecorrido}
                                DesmarcarPuntoRecorrido={this.props.DesmarcarPuntoRecorrido}
                                sender={this.fetchDataComponent}>
                            </TablaPosicionesAgrupadas>
                        </div>
                    )
                    return finalRender;
                }
            }
            </FetchData>
        );

        let finalRender = (
            <div id="RecorridoPage">
                <div className="FiltrosRow">
                    <Calendario placeholder="Seleccione un rango de fechas" id="CalendarioRecorrido" desdeHasta={this.state.desdeHasta} onChange={(e) => this.setState({ desdeHasta: e.value })} />
                    <div className="input-group w-auto">
                        <div className="input-group-append">
                            <button className="btn btn-naranja" onClick={(e) => this.VerRecorrido_Click(e)}>Ver&nbsp;recorrido</button>
                        </div>
                    </div>
                </div>

                {posiciones}
            </div>
        );

        return finalRender;
    }
}

const TablaPosicionesAgrupadas = (props) => {
    const data = props.data;
    const tienePuntosRecorrido = data.TienePuntos;
    const pageSize = data.PageSize;
    const totalPages = data.TotalPages;
    const currentPage = data.CurrentPage;
    const totalRecords = pageSize * totalPages;

    var mensaje = "La unidad no posee ninguna posición para el periodo solicitado.";
    let finalRender = <div className="SinPosiciones"><SinDatos mensaje={mensaje} /></div>;

    if (tienePuntosRecorrido) {
        const puntosRecorrido = data.PuntosRecorrido;

        var renderPosicion = puntosRecorrido.map(function (puntoRecorrido, index) {
            return (
                <PuntoRecorrido
                    key={index}
                    index={index}
                    puntoRecorrido={puntoRecorrido}
                    MarcarPuntoRecorrido={props.MarcarPuntoRecorrido}
                    DesmarcarPuntoRecorrido={props.DesmarcarPuntoRecorrido}>
                </PuntoRecorrido>
            )
        });

        finalRender = (
            <div>
                <div className="Posiciones">
                    <ScrollPanel style={{ height: '100%' }} className="customScroll">
                        {renderPosicion}
                    </ScrollPanel>
                </div>

                <div className="Paginador">
                    <PaginationComponent
                        currentPage={currentPage}
                        totalRecords={totalRecords}
                        onChange={e => props.onPageChange(props.sender, e)} />
                </div>
            </div>
        );
    }

    return finalRender;
};

const PuntoRecorrido = (props) => {
    var arrayColores = ["black", "text-rojo", "text-naranja", "text-verde", "text-gris-medio"];
    var puntoRecorrido = props.puntoRecorrido;
    var index = props.index;

    var posEnRecorrido = puntoRecorrido.PosEnRecorrido;
    var fechaInicial = puntoRecorrido.FechaInicial;
    var fechaFinal = puntoRecorrido.FechaFinal;
    var ubicacion = Functions.decodeHtml(puntoRecorrido.Ubicacion);
    var velDir = puntoRecorrido.VelDirAvg;
    var tiempoEnPosicion = puntoRecorrido.TiempoEnPosicion;
    var urlIcono = puntoRecorrido.UrlIcono;
    var cssVelocidad = "Velocidad " + arrayColores[puntoRecorrido.ColorIcono];

    // POSICIÓN EN RECORRIDO
    var posEnRecorridoRender = null;
    if (posEnRecorrido > 1) {
        posEnRecorridoRender = <div className="PosEnRecorrido">{posEnRecorrido}</div>
    }

    // ESTADO MOTOR
    var estadoMotorRender = null;
    if (puntoRecorrido.NroApagadosMotor >= 1) {
        estadoMotorRender = (
            <div>
                <div>• Estado del motor</div>
                <div>• Tiempo con motor apagado: {puntoRecorrido.TiempoMotorApagado}</div>
                <div>• Tiempo con motor encendido: {puntoRecorrido.TiempoMotorEncendido}</div>
            </div>
        );
    }

    // TOOLTIP
    var descripcionTooltip = (
        <div>
            <div>Número de reportes: {puntoRecorrido.NroReportes}</div>
            <div>Tiempo promedio entre reportes: {puntoRecorrido.TiempoPromedioEntreReportes}</div>
            {estadoMotorRender}
        </div>
    );

    return (
        <div className="Posicion" onMouseEnter={e => props.MarcarPuntoRecorrido(posEnRecorrido)} onMouseLeave={e => props.DesmarcarPuntoRecorrido(posEnRecorrido)}>
            <div className="PosicionIcono">
                <div className="UrlIcono">
                    <img src={urlIcono} alt="Detector Icono" />
                    {posEnRecorridoRender}
                </div>
                <div className="TiempoEnPosicion">{tiempoEnPosicion}</div>
            </div>

            <div className="PosicionInfo">
                <div className="RangoFecha">
                    <div>{fechaInicial}</div>
                    <div>{fechaFinal}</div>
                </div>
                <div className="Ubicacion">{ubicacion}</div>
                <div className={cssVelocidad}>{velDir}</div>
            </div>

            <div>
                <TooltipComponent index={index} descripcion={descripcionTooltip} icono={OkSvg} className="bg-gris-claro" />
            </div>
        </div>
    );
};

export default RecorridoPage;