import React from 'react';
import FetchData from '../../Helpers/FetchData';
import Calendario from '../../Helpers/Calendario'
import Functions from '../../Helpers/Functions'
import { ScrollPanel } from 'primereact/scrollpanel';
import SinDatos from '../../Helpers/SinDatos';
import DownloadButton from '../../Helpers/Forms/DownloadButton';
import PaginationComponent from '../../Helpers/Forms/PaginationComponent.js';
import IconComponent from '../../Helpers/Forms/IconComponent';
import CalendarSvg from '../../../../assets/images/Calendar.svg';
import ClockSvg from '../../../../assets/images/Clock.svg';
import StarSvg from '../../../../assets/images/Star.svg';
import OkSvg from '../../../../assets/images/Ok.svg';
import PinSvg from '../../../../assets/images/Pin.svg';
import NoMapSvg from '../../../../assets/images/NoMap.svg';
import SvgComponent from '../../Helpers/SvgComponent';
import TooltipComponent from '../../Helpers/Forms/TooltipComponent';

class NotificacionesPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            desdeHasta: ['', ''],
            notificaciones: [],
            cargarNotificaciones: true,
            notificarFiltros: true
        }

        this.fetchDataComponent = React.createRef();
    }

    ValidarFechas() {
        var datFI = new Date(1980, 1, 1);
        var datFF = new Date(1980, 1, 1);
        var retorno = false;

        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        // Valida la fecha
        var tmpDia = Functions.isEmpty(desde) ? "" : desde.getDate();
        var tmpMes = Functions.isEmpty(desde) ? "" : desde.getMonth() + 1;
        var tmpAno = Functions.isEmpty(desde) ? "" : desde.getFullYear();
        var enaFI = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFI);

        tmpDia = Functions.isEmpty(hasta) ? "" : hasta.getDate();
        tmpMes = Functions.isEmpty(hasta) ? "" : hasta.getMonth() + 1;
        tmpAno = Functions.isEmpty(hasta) ? "" : hasta.getFullYear();
        var enaFF = Functions.ValidateDateValues(tmpDia, tmpMes, tmpAno, 2007, datFF);

        if (!enaFI && !enaFF) {
            alert("Debe ingresar al menos una fecha para realizar el filtrado");
        }
        else if (enaFI && enaFF && (datFI > datFF)) {
            alert("La fecha final (hasta) debe ser mayor que la fecha inicial (desde)");
        } else {
            retorno = true;
        }

        return retorno;
    };

    createUrl(pagina) {
        var desde = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[0];
        var hasta = Functions.isEmpty(this.state.desdeHasta) ? "" : this.state.desdeHasta[1];

        const idUnidad = this.props.idUnidadSeleccionada;
        const FI = Functions.isEmpty(desde) ? "" : Functions.ToSQLDate(desde);
        const FF = Functions.isEmpty(hasta) ? "" : Functions.ToSQLDate(hasta);
        const finalUrl = process.env.REACT_APP_SERVICES_NOTIFICACIONES
            + "IdUnidad=" + idUnidad
            + "&Page=" + pagina
            + "&FI=" + FI
            + "&FF=" + FF;
        return finalUrl;
    }

    VerNotificaciones_Click = (event) => {
        var validaciones = this.ValidarFechas();

        if (validaciones) {
            this.setState({ notificarFiltros: true });

            if (this.fetchDataComponent.current == null) {
                this.setState({ cargarNotificaciones: true });
            } else {
                const finalUrl = this.createUrl(0);
                this.fetchDataComponent.current.actualizarData(finalUrl);
            }
        }
    };

    PintarPosicion = (unidad, posicion) => {
        this.props.PintarPosicion(unidad, posicion);
    };

    onPageChange = (fetchComponent, newPage) => {
        var page = newPage - 1;
        const finalUrl = this.createUrl(page);
        fetchComponent.current.actualizarData(finalUrl);
    }

    render() {
        let notificaciones = null;

        if (this.state.cargarNotificaciones) {
            const finalUrl = this.createUrl(0);
            var urlExportacionExcel = finalUrl + "&enExportacion=true";

            notificaciones = (
                <FetchData url={finalUrl} ref={this.fetchDataComponent}>
                    {data => {
                        if (this.state.notificarFiltros) {
                            this.props.VerMapaUltimaPosicion();
                            this.setState({ notificarFiltros: false });
                        }

                        var finalRender = (
                            <div>
                                <div className="PanelOperaciones">
                                    <DownloadButton url={urlExportacionExcel} />
                                </div>
                                <TablaPosiciones
                                    unidad={this.props.unidadSeleccionada}
                                    posiciones={data}
                                    onPageChange={this.onPageChange}
                                    sender={this.fetchDataComponent}
                                    PintarPosicion={this.PintarPosicion}>
                                </TablaPosiciones>
                            </div>)
                        return finalRender;                            
                    }
                }
            </FetchData>
            );
        }

        let finalRender = (
            <div id="NotificacionesPage">
                <div className="FiltrosRow">
                    <Calendario placeholder="Seleccione un rango de fechas" id="CalendarioNotificaciones" desdeHasta={this.state.desdeHasta} onChange={(e) => this.setState({ desdeHasta: e.value })} />
                    <div className="input-group w-auto">
                        <div className="input-group-append">
                            <button className="btn btn-naranja" onClick={(e) => this.VerNotificaciones_Click(e)}>Filtrar</button>
                        </div>
                    </div>
                </div>

                {notificaciones}
            </div>
        );

        return finalRender;
    }
}

const TablaPosiciones = (props) => {
    const objNotificaciones = props.posiciones;
    const tieneNotificaciones = objNotificaciones.TieneNotificaciones;
    const notificaciones = objNotificaciones.Notificaciones;
    const pageSize = objNotificaciones.PageSize;
    const totalPages = objNotificaciones.TotalPages;
    const currentPage = objNotificaciones.CurrentPage;
    const totalRecords = pageSize * totalPages;

    var mensaje = "La unidad no posee ninguna notificación para el periodo solicitado.";
    let finalRender = <div className="SinPosiciones"><SinDatos mensaje={mensaje} /></div>;

    if (tieneNotificaciones) {
        var renderPosicion = notificaciones.map(function (posicion, index) {
            return <Posicion key={index} unidad={props.unidad} posicion={posicion} PintarPosicion={props.PintarPosicion} />
        });

        finalRender = (
            <div>
                <div className="Posiciones">
                    <ScrollPanel style={{ height: '100%' }} className="customScroll">
                        {renderPosicion}
                    </ScrollPanel>
                </div>

                <div className="Paginador">
                    <PaginationComponent
                        currentPage={currentPage}
                        totalRecords={totalRecords}
                        onChange={e => props.onPageChange(props.sender, e)} />
                </div>
            </div>
        )
    }

    return finalRender;
};

const Posicion = (props) => {
    var index = props.index;
    var id = props.posicion.IdPosicion;
    var fecha = props.posicion.Fecha;
    var hora = props.posicion.Hora;
    var descripcion = props.posicion.Descripcion;
    var tituloTransmision = props.posicion.TituloTransmision;
    var tipoTransmision = props.posicion.TipoTransmision;
    var descripcionTransmision = props.posicion.DescripcionTransmision;
    var posicion = props.posicion.Posicion;

    var arrayIconos = [OkSvg, ClockSvg, ClockSvg, ClockSvg, ClockSvg];
    var iconoTooltip = arrayIconos[tipoTransmision - 1];

    // RENDER POSICIÓN
    var renderPosicion = null;
    if (posicion === null) {
        renderPosicion = <IconComponent className="bg-rojo text-light" icono={NoMapSvg} />;
    } else {
        renderPosicion = <IconComponent className="bg-naranja text-light" icono={PinSvg} />;
    }

    return (
        <div className="FuncionEnviada" key={index} onMouseEnter={() => props.PintarPosicion(props.unidad, posicion)}>
            <div>
                <Fila icono={CalendarSvg} valor={fecha} />
                <Fila icono={ClockSvg} valor={hora} />
                <Fila icono={StarSvg} valor={descripcion} className="font-weight-bold" />
            </div>
            <div className="FuncionIconos">
                {renderPosicion}
                <TooltipComponent index={index} descripcion={descripcionTransmision} icono={iconoTooltip} className="bg-gris-claro" />
            </div>
        </div>
    );
};

const Fila = (props) => {
    var finalClassName = "FuncionValorRow " + (props.className == undefined ? "" : props.className);

    var renderFinal = (
        <div>
            <span className="FuncionIconoRow">
                <SvgComponent icono={props.icono} />
            </span>
            <span className={finalClassName}>
                {props.valor}
            </span>
        </div>
    );
    return renderFinal
};

export default NotificacionesPage;