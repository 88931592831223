import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const CheckboxComponent = (props) => {
    var finalRender = (
        <Checkbox label={props.label} checked={props.checked} readOnly={props.readOnly} onChange={props.onChange} />
    );
    return finalRender
};

export default CheckboxComponent;
