import React from 'react';
import Header from './Header/Header';
import ContentController from './ContentController';
import MapaController from './MapaController';
import ProgressBar from './Helpers/ProgressBar';
import { withRouter } from 'react-router-dom';
import Functions from './Helpers/Functions';
import { Redirect } from 'react-router-dom';
import { Route, Switch, Link } from 'react-router-dom'

class App extends React.Component {
  constructor(props) {
    super(props)

    // MAPA INICIAL
    var paginasUltimaPosicion = ["Resumen", "FuncionesEnviadas", "Notificaciones", "EnviarFuncion", "Geocercas", "Combustible"];
    var paginaPorUrl = this.props.location.pathname.replace("/", "");
    var mapaInicial;
    if (paginaPorUrl === "") {
      mapaInicial = 1;
    } else if (Functions.isInArray(paginaPorUrl, paginasUltimaPosicion)) {
      mapaInicial = 2;
    } else if (paginaPorUrl === "Recorrido") {
      mapaInicial = 3;
    }

    this.state = {
      traducciones: null,
      tipoMapaSeleccionado: mapaInicial,
      usuarioActual: { NombreUsuario: "NOMBRE USUARIO", IdCuenta: 1, NombreCuenta: "NOMBRECUENTA" },
      actualizarInformacionUnidad: false
    }

    this.autenticacionCorrecta = this.autenticacionCorrecta.bind(this);

    this.mapaComponent = React.createRef();
  }

  autenticacionCorrecta = (infoUsuario) => {
    this.setState({ usuarioActual: infoUsuario });
  };

  // MAPAS
  VerMapaTodasMisUnidades = () => {
    this.setState({ tipoMapaSeleccionado: 1 });
  };

  VerMapaUltimaPosicion = () => {
    this.setState({ tipoMapaSeleccionado: 2 });
  };

  VerMapaRecorrido = () => {
    this.setState({ tipoMapaSeleccionado: 3 });
  };

  // EVENTOS MAPA DE TODAS MIS UNIDADES
  PintarUnidades = (unidades) => {
    this.mapaComponent.current.PintarUnidades(unidades);
  };

  MarcarUnidad = (unidad) => {
    this.mapaComponent.current.MarcarUnidad(unidad);
  };

  DesmarcarUnidad = (unidad) => {
    this.mapaComponent.current.DesmarcarUnidad(unidad);
  };

  // EVENTOS MAPA DE POSICIÓN
  PintarPosicion = (unidad, posicion) => {
    this.mapaComponent.current.PintarPosicion(unidad, posicion);
  };

  // EVENTOS MAPA DE RECORRIDO
  FiltrarMapaRecorrido = (idUnidad, FIParameter, FFParameter) => {
    this.mapaComponent.current.FiltrarMapaRecorrido(idUnidad, FIParameter, FFParameter);
  };

  MarcarPuntoRecorrido = (timeStamp) => {
    this.mapaComponent.current.MarcarPuntoRecorrido(timeStamp);
  }

  DesmarcarPuntoRecorrido = (timeStamp) => {
    this.mapaComponent.current.DesmarcarPuntoRecorrido(timeStamp);
  }

  render() {
    var finalRender = (
      <Autenticacion autenticacionCorrecta={this.autenticacionCorrecta} {...this.props} >
        <div id="app">
          <Header usuarioActual={this.state.usuarioActual} />
          <div id="app-content">
            <ContentController
              {...this.state}

              VerMapaTodasMisUnidades={this.VerMapaTodasMisUnidades}
              VerMapaUltimaPosicion={this.VerMapaUltimaPosicion}
              VerMapaRecorrido={this.VerMapaRecorrido}

              PintarUnidades={this.PintarUnidades}
              MarcarUnidad={this.MarcarUnidad}
              DesmarcarUnidad={this.DesmarcarUnidad}

              FiltrarMapaRecorrido={this.FiltrarMapaRecorrido}
              MarcarPuntoRecorrido={this.MarcarPuntoRecorrido}
              DesmarcarPuntoRecorrido={this.DesmarcarPuntoRecorrido}

              PintarPosicion={this.PintarPosicion}>
            </ContentController>

            <MapaController {...this.state} ref={this.mapaComponent} />
          </div>
        </div>
      </Autenticacion >
    );

    return finalRender;
  }
}

class Autenticacion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cargando: true,
      autorizado: false
    }
  }

  componentDidMount() {
    var url = process.env.REACT_APP_SERVICES_INFO;

    fetch(url)
      .then((response) => {
        return response.text();
      })
      .then((result) => {
        var noAutorizado = "<SCRIPT>parent.location.href = './Default.aspx';</SCRIPT>";
        if (result === noAutorizado) {
          this.setState({ cargando: false, autorizado: false });
        }
        else {
          var data = JSON.parse(result);
          this.props.autenticacionCorrecta(data);
          this.setState({ cargando: false, autorizado: true });
        }
      })
      .catch((err) => {
        this.setState({ cargando: false, autorizado: false });
      });
  };

  render() {
    var finalRender;

    if (this.state.cargando) {
      finalRender = <ProgressBar showProgressBar={true} message="Cargando plataforma ..." />
    } else {
      if (this.state.autorizado) {
        finalRender = this.props.children;
      } else {
        window.location = process.env.REACT_APP_SERVICES_DEFAULT;
        finalRender = null;
      }
    }

    return finalRender;
  }
}

export default withRouter(App);