import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import Button from 'react-bootstrap/Button';
import IFrame from '../IFrame';
import { Dialog } from 'primereact/dialog';
import './MyVerticallyCenteredModal.css';

const ModalComponent = (props) => {
    let finalRender = null;

    if (props.visible) {
        finalRender = (
            <Dialog maximizable header={props.title} visible={props.visible} onHide={props.onHide}>
                {props.children}
            </Dialog>
        );
    }

    return finalRender;
}

export default ModalComponent;