import React from 'react';
import IFrame from '../../Helpers/IFrame';

class MapaUltimaPosicion extends React.Component {
  constructor(props) {
    super(props)

    this.iframeComponent = React.createRef();
  }

  createUrl() {
    const finalUrl = process.env.REACT_APP_SERVICES_MAPAULTIMAPOSICION
    return finalUrl;
  }

  PintarPosicion(unidad, posicion) {
    if (unidad !== null && posicion !== null) {
      this.iframeComponent.current.PintarPosicion(unidad, posicion);
    }
  }

  render() {
    // IFRAME
    const finalUrl = this.createUrl();
    var iframeRender = <IFrame src={finalUrl} ref={this.iframeComponent} />

    let finalRender = (
      <div id="MapaUltimaPosicion">
        {iframeRender}
      </div>
    );

    return finalRender;
  }
}

export default MapaUltimaPosicion;