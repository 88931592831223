import React from 'react';
import SvgComponent from '../Helpers/SvgComponent';
import CarroSvg from '../../../assets/images/Car.svg';
import PersonaSvg from '../../../assets/images/Person.svg';
import MotoSvg from '../../../assets/images/Moto.svg';
import CajaSvg from '../../../assets/images/Box.svg';

const TipoVehiculo = (props) => {
	var tipoVehiculo = props.tipoVehiculo;
	var imagen = CarroSvg;

	if (tipoVehiculo === "Carro")
		imagen = CarroSvg;
	else if (props.tipoVehiculo === "Persona")
		imagen = PersonaSvg;
	else if (props.tipoVehiculo === "Moto")
		imagen = MotoSvg;
	else if (props.tipoVehiculo === "Caja")
		imagen = CajaSvg;

	return (
		<SvgComponent icono={imagen} />
	);
};

export default TipoVehiculo;