import React from 'react';

const BotonComponent = (props) => {
    var finalRender = (
        <button className="btn btn-naranja" onClick={props.onClick}>
            {props.value}
        </button>
    );
    return finalRender
};

export default BotonComponent;