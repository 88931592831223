import React from 'react';
import Moment from 'react-moment';

const DateFormat = (props) => {
    var finalRender = (
        <Moment format="D-MMM-YYYY h:mm:ss A" interval={0}>
            {props.fecha}
        </Moment>
    );
    return finalRender
};

export default DateFormat;