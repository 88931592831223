import React from 'react';
import ProgressBar from "../Helpers/ProgressBar";
import { render } from 'react-dom';
import Async from "react-async";

// const getUrl = async ({ url }, { signal }) => {
//     const response = await fetch(url, { signal })

//     if (!response.ok)
//         throw new Error(response.statusText)

//     const data = await response.json();
//     return data;
// }

// const AsyncData = (props) => (
//     <Async promiseFn={getUrl} url={props.url}>
//         <Async.Pending>
//             <ProgressBar showProgressBar={true}></ProgressBar>
//         </Async.Pending>
//         <Async.Fulfilled>
//             {data => props.children(data)}
//         </Async.Fulfilled>
//         <Async.Rejected>
//             {(error, { finishedAt }) => `Something went wrong: ${error.message}`}
//         </Async.Rejected>
//     </Async>
// )


// class FetchData extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             internal: this.props.interval
//         };
//     }

//     render() {
//         var finalRender = (
//             <AsyncData url={this.props.url}>
//                 {data => this.props.children(data)}
//             </AsyncData>
//         );

//         return finalRender;
//     }
// }


class FetchData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            errorFetching: false,
            retrying: false,
            intentosEjecutados: 0,
            data: []
        };

        this.cantidadReintentos = 3;
        this.tiempoEntreReintentos = 1000;

        // LIMPIAR EL SetTimeout
        this.funcionSetInterval = null;
    }

    componentWillUnmount() {
        clearTimeout(this.funcionSetInterval);
    }

    actualizarData(url) {
        this.setState({ isFetching: true, errorFetching: false, retrying: false, intentosEjecutados: 0, data:[] });
        this.fetchDataWithRetry(url);
    }

    componentDidMount() {
        this.fetchDataWithRetry(this.props.url);
    };

    fetchDataWithRetry(url) {
        const fetchAttempt = (intentosFaltantes) => { 
            fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("not ok");
                }

                return response.json();
            })
            .then((result) => {
                this.setState({ isFetching: false, data: result });
            })
            .catch((err) => {
                if (intentosFaltantes < 1) {
                    this.setState({ isFetching: false, errorFetching: true, retrying: false });

                    console.info(`Error fetch (Error: ${err})`);
                } else {
                    this.setState({ retrying: true, intentosEjecutados: (this.cantidadReintentos - intentosFaltantes) + 1});
                    this.funcionSetInterval = setTimeout(() => fetchAttempt(intentosFaltantes - 1), this.tiempoEntreReintentos);

                    console.log(`Attempt ${this.state.intentosEjecutados}. Error fetch (Error: ${err})`);
                }
            });
        };

        // INICIAR EL PRIMER INTENTO
        fetchAttempt(this.cantidadReintentos);
    };

    render() {
        if (this.state.retrying)
            return <div className="fade alert alert-danger show m-3 p-2">Verifique su conexión a Internet (reintento {this.state.intentosEjecutados} de {this.cantidadReintentos})</div>
        
        if (this.state.isFetching)
            return <ProgressBar showProgressBar={true}></ProgressBar>;
        
        if (this.state.errorFetching)
            return (<div className="fade alert alert-danger show m-3 p-2">
                        <div>Verifique su conexión a Internet</div>
                        <button className="btn btn-rojo" onClick={(e) => this.actualizarData(this.props.url)}>Reintentar</button>
                    </div>)
        
        return (this.props.children(this.state.data));
    }
}

export default FetchData;